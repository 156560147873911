<template>
  <div class="setting-card">
    <div class="form-row align-items-center">
      <div class="col">
        <span class="custom-label" v-bind:class="{ 'text-muted': disabled }">{{
          title
        }}</span>
        <div class="d-block">
          <small class="text-muted">
            {{ description }}
            <template v-if="showStatus"> - {{ getStatusText }} </template>
          </small>
        </div>
      </div>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          v-bind:id="name"
          v-model="checked"
          v-on:change="onChange"
          v-bind:disabled="disabled"
        />
        <label class="custom-control-label" v-bind:for="name"></label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WeSettingCard",
  data() {
    return {
      data: {
        value: false,
      },
    };
  },
  props: {
    title: {
      default: null,
    },
    description: {
      default: null,
    },
    name: {
      default: null,
    },
    value: {
      default: false,
    },
    showStatus: {
      default: true,
    },
    disabled: {
      default: false,
    },
  },
  methods: {
    onChange() {
      this.$emit("input", this.data.value);
    },
  },
  computed: {
    checked: {
      get() {
        return this.value === 1;
      },
      set(val) {
        this.data.value = val === true ? 1 : 0;
      },
    },
    getStatusText() {
      return this.checked ? "Aktif" : "Pasif";
    },
  },
};
</script>
<style lang="scss" scoped>
.setting-card {
  cursor: default;
  transition: 0.2s all;
  padding: 0.5rem 0.5rem 0.5rem 0;
  border-radius: 6px;
  &:hover {
    padding-left: 1rem;
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.2);
  }
}
</style>
